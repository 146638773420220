import {
  useState,
  useMemo,
  createContext,
  useEffect,
  useContext,
  Dispatch,
  SetStateAction,
  ReactNode,
  FC
} from 'react';
import {
  searchFiltersInitialState,
  SearchFiltersInterface,
  SearchFiltersTypes
} from '../../../reducers/search-filters/reducer';
import {
  SearchFiltersActionTypes,
  SearchFiltersActions
} from '../../../reducers/search-filters/actions';
import { filterOptions } from '../../../new-lib/types/search-filters';
import useSearchFilters from '../../../hooks/SearchFilters';
import { useRouter } from 'next/router';
import { initializeSearchFiltersFromQuery } from '../../../helpers/search-filters';
import { containsAnyWord } from '../../../helpers/common';
import { DropdownOptionDto } from '../dtos/dropdown-option.dto';
import { useSelector } from 'react-redux';
import SearchBar from '../components/search-bar/SearchBar';
import { useCleanPath } from '../../../hooks/paths.hooks';

export interface SearchContextInterface {
  filterOptions: filterOptions;
  showFilters: boolean;
  setShowFilters: Dispatch<SetStateAction<boolean>>;
  searchFiltersState: SearchFiltersInterface;
  searchFiltersDispatch: Dispatch<SearchFiltersActions>;
  showUniversalSearch: boolean;
  setShowUniversalSearch: Dispatch<SetStateAction<boolean>>;
  appliedFiltersCount: number;
  maxDeliveryYear?: number;
  areas: DropdownOptionDto[];
  setAreas: Dispatch<SetStateAction<DropdownOptionDto[]>>;
  developers: DropdownOptionDto[];
  setDevelopers: Dispatch<SetStateAction<DropdownOptionDto[]>>;
}

export const SearchContextDefaultValue: SearchContextInterface = {
  filterOptions: {},
  showFilters: false,
  setShowFilters: () => {},
  searchFiltersState: searchFiltersInitialState,
  searchFiltersDispatch: () => {},
  showUniversalSearch: false,
  setShowUniversalSearch: () => {},
  appliedFiltersCount: 0,
  areas: [],
  setAreas: () => {},
  developers: [],
  setDevelopers: () => {}
};

export const SearchContext = createContext<SearchContextInterface>(
  SearchContextDefaultValue
);

export const useSearchContext = () => useContext(SearchContext);

interface SearchProviderProps {
  filterOptions: any;
  areas?: DropdownOptionDto[];
  developers?: DropdownOptionDto[];
  children: ReactNode;
}

const pathsToSetFiltersFromQuery = [
  '/search',
  '/compound',
  '/area',
  '/developer',
  '/favorites',
  '/nawy-now',
  '/affordability-results'
];

const pathsWithoutUniversalSearch = [
  '/',
  '/cityscape',
  '/compare',
  '/map',
  '/nawy-now',
  '/verify-agent',
  '/nawy-unlocked',
  '/favorites',
  '/sell-my-property',
  '/about-us',
  '/terms',
  '/privacy-policy',
  '/area',
  '/developer',
  '/top-compounds'
];

const SearchProvider: FC<SearchProviderProps> = ({
  filterOptions,
  areas: ssrAreas,
  developers: ssrDevelopers,
  children
}) => {
  const { query, asPath, isReady } = useRouter();
  const path = useCleanPath();
  const isMobile = useSelector((state: any) => state.app.isMobile);
  const [areas, setAreas] = useState<DropdownOptionDto[]>(ssrAreas ?? []);
  const [developers, setDevelopers] = useState<DropdownOptionDto[]>(
    ssrDevelopers ?? []
  );
  const [showFilters, setShowFilters] = useState(false);
  const [showUniversalSearch, setShowUniversalSearch] = useState(
    isMobile &&
      path !== '/' &&
      !pathsWithoutUniversalSearch.some(pathLink => {
        return path.includes(pathLink) && pathLink !== '/';
      })
  );
  const { searchFiltersState, dispatch: searchFiltersDispatch } =
    useSearchFilters();
  const appliedFiltersCount = [
    searchFiltersState.searchFilters.bathrooms.length > 0,
    searchFiltersState.searchFilters.bedrooms.length > 0,
    searchFiltersState.searchFilters.delivery_date != undefined &&
      !asPath.includes('/nawy-now'),
    searchFiltersState.searchFilters.delivery_years.length > 0,
    searchFiltersState.searchFilters.down_payment != undefined,
    searchFiltersState.searchFilters.finishing.length > 0,
    searchFiltersState.searchFilters.installment_years.length > 0,
    searchFiltersState.searchFilters.max_price != undefined,
    searchFiltersState.searchFilters.max_unit_area != undefined,
    searchFiltersState.searchFilters.min_price != undefined,
    searchFiltersState.searchFilters.min_unit_area != undefined,
    searchFiltersState.searchFilters.monthly_installments != undefined,
    searchFiltersState.searchFilters.property_types.length > 0,
    searchFiltersState.searchFilters.sale_type.length > 0 &&
      asPath.includes('/search'),
    searchFiltersState.searchFilters.amenities.length > 0 &&
      (asPath.includes('/search') ||
        searchFiltersState.searchFilters.amenities.includes(1) ||
        searchFiltersState.searchFilters.amenities.includes(2)),
    searchFiltersState.searchFilters.has_garden,
    searchFiltersState.searchFilters.has_roof,
    searchFiltersState.searchFilters.nawy_now && !asPath.includes('/nawy-now'),
    searchFiltersState.searchFilters.compounds.length > 0 &&
      asPath.includes('/nawy-now'),
    searchFiltersState.searchFilters.areas.length > 0 &&
      (asPath.includes('/nawy-now') || asPath.includes('/search')),
    searchFiltersState.searchFilters.developers.length > 0 &&
      asPath.includes('/search') &&
      query?.view == 'map'
  ].filter(filter => filter).length;

  useEffect(() => {
    if (
      (!asPath.includes('/search') || query?.view === 'map') &&
      (!isMobile || query?.view === 'map') &&
      searchFiltersState.searchFilters.amenities.filter(
        amenity => amenity !== 1 && amenity !== 2
      ).length
    ) {
      searchFiltersDispatch({
        type: SearchFiltersActionTypes.UPDATE_FILTERS_WITH_MULTISELECT,
        payload: {
          name: SearchFiltersTypes.AMENITIES,
          value: searchFiltersState.searchFilters.amenities.filter(
            amenity => amenity === 1 || amenity === 2
          )
        }
      });
    }
  }, [isMobile, asPath, searchFiltersState.searchFilters]);
  useEffect(() => {
    // reset all filters at the starting point of the search journey
    if (asPath === '/') {
      searchFiltersDispatch({ type: SearchFiltersActionTypes.RESET });
    }
  }, [asPath]);
  useEffect(() => {
    setShowUniversalSearch(
      isMobile &&
        path !== '/' &&
        !pathsWithoutUniversalSearch.some(pathLink => {
          return path.includes(pathLink) && pathLink !== '/';
        })
    );
  }, [path, isMobile]);

  useEffect(() => {
    if (
      isReady &&
      containsAnyWord(asPath, pathsToSetFiltersFromQuery) &&
      Object.keys(query).length > 0
    ) {
      initializeSearchFiltersFromQuery(query, searchFiltersDispatch);
    }
  }, [asPath, query, isReady]);

  const getMaxDeliveryYear = () => {
    let lastDeliveryDateIndex = filterOptions.delivery_date?.length - 1;
    return filterOptions.delivery_date[lastDeliveryDateIndex]?.value;
  };

  const memoizedContext = useMemo(
    () => ({
      filterOptions,
      showFilters,
      setShowFilters,
      searchFiltersState,
      searchFiltersDispatch,
      showUniversalSearch,
      setShowUniversalSearch,
      appliedFiltersCount,
      maxDeliveryYear: getMaxDeliveryYear(),
      areas,
      setAreas,
      developers,
      setDevelopers
    }),
    [
      filterOptions,
      showFilters,
      setShowFilters,
      searchFiltersState,
      searchFiltersDispatch,
      showUniversalSearch,
      setShowUniversalSearch,
      appliedFiltersCount,
      getMaxDeliveryYear,
      areas,
      developers
    ]
  );
  return (
    <SearchContext.Provider value={memoizedContext}>
      {children}
    </SearchContext.Provider>
  );
};

export default SearchProvider;
