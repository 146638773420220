import IconHolder from '../../ui/IconHolder';
import ImageWrapper from '../../ImageWrapper';
import Language from '../../../public/assets/icons/common/language.svg';
import { LanguageUtils } from '../../../new-lib/utilities/language-utils';
import {
  LANGUAGE,
  LANGUAGE_LABEL
} from '../../../new-lib/constants/i18n-constants';
import { useLanguageSwitch } from '../../../hooks/i18n-hooks';
import { useRouter } from 'next/router';

interface Props {
  setIsOpen?: React.Dispatch<React.SetStateAction<boolean>>;
  showIcon: boolean;
}

export const HeaderLanguage = ({ showIcon, setIsOpen }: Props) => {
  const { language, changeLanguage } = useLanguageSwitch();
  const { asPath } = useRouter();
  const isTopCompoundPage = asPath.includes('/top-compounds');
  return isTopCompoundPage ? null : (
    <div
      className="language-icon"
      onClick={() => {
        changeLanguage();
        if (setIsOpen) {
          setIsOpen(false);
        }
      }}
      data-test="switch-lang-btn"
    >
      {showIcon && (
        <IconHolder>
          <ImageWrapper src={Language} alt="Language" width={30} height={40} />
        </IconHolder>
      )}
      <span>
        {LanguageUtils.isArabic(language)
          ? LANGUAGE_LABEL[LANGUAGE.ENGLISH]
          : LANGUAGE_LABEL[LANGUAGE.ARABIC]}
      </span>
    </div>
  );
};
